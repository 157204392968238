.teams {
    &:before {
        height: 10.3rem;
        display: block;
        content: '';
    }
    display: flex;
    flex-direction: column;
    gap: 3.7rem;
}

.team {
    &:last-child {
        margin-bottom: 4rem;
    }
}

.no-result {
    font-size: xx-large;
    margin: 0 auto;
    width: auto;
    position: absolute;
    left: 50%;
    top: 20rem;
    transform: translateX(-50%);
}
