@import 'variables';
.load {
    height: 100%;
    width: 100%;
    position: absolute;
    > span {
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        position: absolute;
    }
}
.icon {
    color: $primary-theme-color;
}
