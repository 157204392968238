@import 'variables';

$employee-item-padding: 1.2rem;

.employee-item {
    padding: $employee-item-padding;
    background: white;
    font-size: 1.4rem;
    border-radius: 1.6rem;
    position: relative;
    a {
        color: white;
        text-decoration: none;
        font-weight: 600;
    }
    &.highlight {
        background: $highlighted-color;
    }
    &:hover {
        .slack-and-hiBob-links {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.avatar-icon-container {
    width: $avatar-image-width;
    height: $avatar-image-width;
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
}

.icon {
    color: $primary-theme-color;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.loading-icon {
    width: 100%;
    height: 100%;
    opacity: 0;
    mask: linear-gradient(135deg, #000 30%, #0008, #000 75%) right/500% 100%;
    background-repeat: no-repeat;
    animation: shimmerAnimation 1.5s ease infinite forwards, pulsate 1.5s linear infinite;
}

@keyframes pulsate {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

@keyframes shimmerAnimation {
    100% {
        mask-position: left;
    }
}

.avatar {
    width: $avatar-image-width;
    height: $avatar-image-width;
    border-radius: 0.8rem;
    object-fit: cover;
    margin-bottom: 1rem;
}

.name {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.92rem;
    text-align: left;
    margin-bottom: 0.8rem;
    &.highlight {
        color: #ffffff;
    }
}

.pill {
    background-color: #f5f7fa;
    border-radius: 0.4rem;
    display: inline-block;
    padding: 0.4rem 0.8rem;
    overflow: hidden;
    &.title {
        padding: 0.6rem 0.8rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.status-list {
    $topAndLeft: 0.8rem;
    list-style: none;
    position: absolute;
    top: calc($employee-item-padding + $topAndLeft);
    left: calc($employee-item-padding + $topAndLeft);
    flex-wrap: wrap;
    gap: 0.4rem 0.8rem;
    margin: 0;
    padding: 0;
    display: flex;
    width: calc($avatar-image-width - ($topAndLeft * 2));
    z-index: 1;
}

.role {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6rem;
    text-align: left;
}

.multiline {
    display: table-caption;
}

.name-and-role-container {
    width: $avatar-image-width;
}

.slack-and-hiBob-links {
    position: absolute;
    top: $employee-item-padding;
    left: $employee-item-padding;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $avatar-image-width;
    height: $avatar-image-width;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
        rgb(211, 211, 211, 0.4) 50% / cover no-repeat;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.8rem;
    opacity: 0;
    transition: opacity 0.25s ease;
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 0.4rem 0.8rem;
        margin: 0;
        padding: 0;
        justify-content: center;
    }
}

.link-icon {
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    &.hiBob {
        background-image: url('/images/hiBob.svg');
    }
    &.slack {
        background-image: url('/images/slack.svg');
    }
}
