.switch {
  position: relative;
  display: inline-block;
  width: 152px;
  height: 40px;
  cursor: pointer;
  //right: 2.4rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D4D9DD;
  -webkit-transition: .4s;
  border-radius: 8px;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 72px;
    left: 4px;
    bottom: 4px;
    border-radius: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;

  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(72px);
  -ms-transform: translateX(72px);
  transform: translateX(72px);
}

.name{
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 50%;
  left: 25%;
  transform: translateY(-50%) translateX(-50%);
  pointer-events: none;
}
.ca{
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  pointer-events: none;
  top: 50%;
  left: 75%;
  transform: translateY(-50%) translateX(-50%);
}

.wrapper{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}