@import 'variables';
.container {
    height: 10rem;
    background-color: $primary-theme-color;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 99;
    .wrapper {
        max-width: 120rem;
        margin: 0 auto;
        position: relative;
        height: 100%;
    }
}

.content-wrapper {
    position: relative;
    padding: 0 2.4rem;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    ul {
        left: 0;
        padding-top: 1rem;
        margin-top: -1rem;
        z-index: -1;
        border-radius: 0.5rem;
        border: 1px solid transparent;
        overflow: hidden;
        font-size: 1.6rem;
        position: absolute;
        background: white;
        list-style: none;
        li {
            padding: 1.4rem;
            &.selected {
                font-family: inherit;
                background: #b4bfc7;
                cursor: pointer;
            }
            @media (hover: hover) {
                &:hover {
                    font-family: inherit;
                    background: #b4bfc7;
                    cursor: pointer;
                }
            }
        }
    }
}

.title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.logo {
    position: relative;
    margin-right: 4rem;
    border: none;
    background: none;

    &:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 800px) {
        display: none;
    }
}

.input-wrapper {
    position: relative;
    flex: 1;
    margin-right: 2.4rem;
}

.search-icon,
.clear-button {
    height: 2.4rem;
    width: 2.4rem;
    left: 1.6rem;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
}
.clear-button {
    right: 1.6rem;
    left: unset;
    top: 35%;
    border: none;
    background: none;
    &:hover {
        cursor: pointer;
    }
}

.input {
    padding: 1.5rem 5.6rem 1.4rem 5.6rem;
    border-radius: 0.8rem;
    border: 1px solid transparent;
    font-size: 1.6rem;
    width: 44.6rem;
    font-family: inherit;
    text-overflow: ellipsis;
    &::placeholder {
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 1.92rem;
    }
    @media only screen and (max-width: 626px) {
        width: 100%;
    }
}

.button {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0.8rem;
    cursor: pointer;
    font-family: inherit;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.44rem;
    padding: 1.2rem 2.4rem;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    height: 4rem;
    &:after {
        content: 'Log out';
    }
    @media only screen and (max-width: 626px) {
        background: transparent;
        border: none;
        padding: 0;
        &:after {
            content: url('../../images/logout.svg');
        }
    }
}
