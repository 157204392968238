@import 'variables';
.container {
    position: relative;
    height: auto;
    margin: 0 auto;
    text-align: center;
    padding: 2rem;
    background-color: $primary-theme-color;
    min-height: 100vh;
}

.image {
    max-width: 54rem;
    width: 100%;
    margin: 8vh 0 5rem;
}

.logo {
    display: block;
    margin: 0 auto 5rem;
}

.title {
    line-height: 3.84rem;
    font-weight: 300;
    font-size: 3.2rem;
    color: #ffffff;
    text-align: center;
    margin-bottom: 5rem;
}

.wrapper {
    width: 100%;
    position: relative;
    height: 4rem;
}

.google {
    position: absolute;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
}
